import { Popover, Transition } from '@headlessui/react';
import useTranslation from 'next-translate/useTranslation';
import { Fragment } from 'react';
import Image from 'next/image';

import {
  ChevronDownIcon,
  PhoneIcon,
  PlayIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import Link from 'next/link';

import Certificates from '../svg/Certificates.svg';
import Documents from '../svg/Documents.svg';
import KNSApp from '../svg/KNSApp.svg';
import Pergolas from '../svg/Pergolas.svg';
import ALU from '../svg/ALU.svg';
import PVC from '../svg/PVC.svg';
import Roller from '../svg/Roller.svg';
import { languages } from '@consts/consts';

function classNames(...classes: unknown[]) {
  return classes.filter(Boolean).join(' ');
}

export const MobileMenu = () => {
  const { t, lang } = useTranslation('common');

  const solutions = [
    {
      name: t('products.pvc'),
      description:
        'Szerokie możliwości stosowania: okna, drzwi okienne, drzwi wejściowe, drzwi przesuwne',
      href: '/pvc',
      icon: PVC,
      products: [
        {
          name: t('pvc.windows'),
          href: '/pvc/windows',
        },
        {
          name: t('pvc.sliding-doors'),
          href: '/pvc/sliding-doors',
        },
        {
          name: t('pvc.doors'),
          href: '/pvc/doors',
        },
        {
          name: t('pvc.accessories'),
          href: '/pvc/accessories',
        },
        {
          name: 'V-Perfect',
          href: '/pvc/v-perfect',
        },
        {
          name: t('pvc.antiBurglary'),
          href: '/pvc/anti-burglary-windows',
        },
      ],
    },
    {
      name: t('products.alu'),
      description:
        'Różnorodność funkcji okien, dostosowana do potrzeb użytkowników',
      href: '/alu',
      icon: ALU,
      products: [
        {
          name: t('alu.windows'),
          href: '/alu/windows',
        },
        {
          name: t('alu.sliding-doors'),
          href: '/alu/sliding-doors',
        },
        {
          name: t('alu.facadeSystems'),
          href: '/alu/facade-systems',
        },
        {
          name: t('alu.doors'),
          href: '/alu/doors',
        },
        {
          name: t('alu.multilevel'),
          href: '/alu/multilevel',
        },
        // {
        //   name: t('alu.winter-gardens'),
        //   href: '/alu/winter-gardens',
        // },
        {
          name: t('alu.accessories'),
          href: '/alu/accessories',
        },
      ],
    },
    {
      name: t('products.rollers'),
      description:
        'Jest to produkt bardzo uniwersalny i wszechstronny, umożliwiający montaż bez zabudowy',
      href: '/rollers',
      icon: Roller,
      products: [
        {
          name: t('rollers.adaptive'),
          href: '/rollers/adaptive',
        },
        {
          name: t('rollers.concealed'),
          href: '/rollers/concealed',
        },
        {
          name: t('rollers.for-windows'),
          href: '/rollers/for-windows',
        },
        {
          name: t('rollers.screens'),
          href: '/rollers/screens',
        },
        {
          name: t('rollers.blinds'),
          href: '/rollers/blinds',
        },
        {
          name: t('rollers.lamella'),
          href: '/rollers/lamella',
        },
        {
          name: t('rollers.accessories'),
          href: '/rollers/accessories',
        },
      ],
    },
    {
      name: t('products.pergolas'),
      description:
        'Drzwi wejściowe to wizytówka Twojego domu! Wybierz solidne drzwi zewnętrzne aluminiowe',
      href: '/pergolas',
      icon: Pergolas,
    },
  ];

  const callsToAction = [
    { name: t('menu.promotionVideos'), href: '/videos', icon: PlayIcon },
    {
      name: t('menu.distributionNetwork'),
      href: '/distribution-network',
      icon: PhoneIcon,
    },
  ];

  const resources = [
    {
      name: t('downloads.certificates'),
      description: t('downloads.certificatesDesc'),
      href: '/certificates',
      icon: Certificates,
    },
    {
      name: t('downloads.program'),
      description: t('downloads.programDesc'),
      href: '/application',
      icon: KNSApp,
    },
    // {
    //   name: t('downloads.remote'),
    //   description: t('downloads.remoteDesc'),
    //   href: '/remote-connection',
    //   icon: RemoteConnection,
    // },
    {
      name: t('downloads.documents'),
      description: t('downloads.documentsDesc'),
      href: '/documents',
      icon: Documents,
    },
    {
      name: t('downloads.advertising'),
      description: t('downloads.advertisingDesc'),
      href: '/advertising-materials',
      icon: Documents,
    },
  ];

  return (
    <Transition
      as={Fragment}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Popover.Panel
        focus
        className="absolute z-50 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
      >
        {({ close }) => (
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img className="h-8 w-auto" src="/kns.webp" alt="Workflow" />
                </div>
                <div className="flex md:hidden items-center justify-end md:flex-1 lg:w-0">
                  <Popover.Group as="nav" className="flex md:hidden space-x-10">
                    <Popover className="relative">
                      <>
                        <Popover.Button
                          className={classNames(
                            'text-gray-900',
                            'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                          )}
                        >
                          <div className="pr-3">
                            <Image
                              src={`/img/locales/${lang}.svg`}
                              alt={lang}
                              width="24"
                              height="24"
                              className="rounded-full drop-shadow-md"
                            />
                          </div>
                          <span>{lang.toUpperCase()}</span>
                          <ChevronDownIcon
                            className={classNames(
                              'text-gray-600',
                              'ml-2 h-5 w-5 group-hover:text-gray-500'
                            )}
                            aria-hidden="true"
                          />
                        </Popover.Button>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute z-30 -ml-4 mt-3 transform px-2  sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <div className="relative grid gap-4 bg-white px-3 py-3 sm:gap-6 sm:p-4">
                                {languages.flatMap(
                                  (item, index) =>
                                    item.locale !== lang && (
                                      <a
                                        key={index}
                                        href={`${item.href}`}
                                        className="-m-3 p-3 pr-8 flex items-start rounded-lg hover:bg-gray-90"
                                      >
                                        <img
                                          src={`/img/locales/${item.locale}.svg`}
                                          alt={item.locale}
                                          width="24"
                                          height="24"
                                          className="rounded-full drop-shadow-md"
                                        />
                                        <div className="ml-3">
                                          <p className="text-base font-medium text-gray-900">
                                            {item.name}
                                          </p>
                                        </div>
                                      </a>
                                    )
                                )}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                      {/* )} */}
                    </Popover>
                  </Popover.Group>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {solutions.map((item, index) => (
                    <Link
                      key={index}
                      href={item.href}
                      onClick={() => close()}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <div className="flex">
                        {/* <item.icon
                            className="flex-shrink-0 h-6 w-6 text-indigo-600"
                            aria-hidden="true"
                          /> */}
                        <span className="ml-3 text-base font-medium text-gray-900">
                          {item.name}
                        </span>
                      </div>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <Link
                  href="/about-us"
                  onClick={() => close()}
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  {t('menu.about')}
                </Link>
                <Link
                  href="/news"
                  onClick={() => close()}
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  {t('menu.news')}
                </Link>
                <Link
                  href="/gallery"
                  onClick={() => close()}
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  {t('menu.gallery')}
                </Link>
                <Link
                  href="/contact"
                  onClick={() => close()}
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  {t('menu.contact')}
                </Link>

                {resources.map((item, index) => (
                  <Link
                    key={index}
                    href={item.href}
                    onClick={() => close()}
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
            <div className="px-3 py-3 bg-gray-50 flex justify-between space-y-0 space-x-2 rounded-b-lg">
              {callsToAction.map((item, index) => (
                <div key={index} className="flow-root">
                  <Link
                    href={item.href}
                    onClick={() => close()}
                    passHref
                    className="-m-3 p-3 flex items-center rounded-md text-xs font-medium text-gray-900 hover:bg-gray-100"
                  >
                    <div className="flex">
                      {/* <item.icon className="h-6 w-6" aria-hidden="true" /> */}
                      <span className="ml-3">{item.name}</span>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}
      </Popover.Panel>
    </Transition>
  );
};
