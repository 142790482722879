import { Popover } from '@headlessui/react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import { MobileMenu } from './mobile';
import { DesktopMenu } from './desktop';

export const Header = () => {
  const { t } = useTranslation('common');
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 150);
    });
  }, []);

  return (
    <>
      <div className="bg-gray-900 text-white relative z-20 hidden md:block">
        <div className="max-w-7xl mx-auto text-xs text-right">
          <Link href="/distribution-network">
            {t('menu.distributionNetwork')}
          </Link>{' '}
          | <Link href="/partners">{t('menu.partners')}</Link> |{' '}
          <Link href="/certificates">{t('downloads.certificates')}</Link> |{' '}
          <Link href="/career">{t('menu.career')}</Link> |{' '}
          <Link href="/contact">{t('menu.contact')}</Link>
        </div>
      </div>
      <Popover
        className={`sticky top-0 bg-white z-20 ${
          scroll ? 'drop-shadow-md' : ''
        }`}
      >
        <DesktopMenu />
        <MobileMenu />
      </Popover>
    </>
  );
};
