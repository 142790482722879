import { getCookie, setCookie } from 'cookies-next';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

export const Footer = () => {
  const { t, lang } = useTranslation('common');
  const cookie = getCookie('cookieConsent');
  const [cookieBar, setCookieBar] = useState('0');

  const hideConsent = () => {
    setCookie('cookieConsent', '1');
    setCookieBar('1');
  };

  useEffect(() => {
    setCookieBar(cookie?.toString() || '0');
  }, []);

  const languages = [
    { name: 'English', locale: 'en', href: 'https://knswindows.com/' },
    { name: 'Polski', locale: 'pl', href: 'https://knsokna.pl/' },
    { name: 'Deutsch', locale: 'de', href: 'https://knsfenster.de/' },
    { name: 'Čeština', locale: 'cs', href: 'https://kns-okna.eu/' },
    { name: 'Italiano', locale: 'it', href: 'https://knsfinestre.it/' },
    { name: 'Français', locale: 'fr', href: 'https://knsfenetres.fr/' },
    { name: 'Magyar', locale: 'hu', href: 'https://knsokna.eu/' },
  ];

  return (
    <>
      <footer className="p-4 mt-8 sm:p-6 bg-gray-900">
        <div className="container mx-auto">
          <div className="md:flex md:justify-between">
            <div className="mb-6 md:mb-0">
              {languages.flatMap(
                (item, index) =>
                  item.locale === lang && (
                    <Link
                      href={item.href}
                      className="flex items-center"
                      key={index}
                    >
                      <img
                        src="/kns-white.webp"
                        className="mr-3 h-20"
                        alt="FlowBite Logo"
                      />
                    </Link>
                  )
              )}
              <div className="mt-4 text-gray-200 text-xs">
                ŚLĄSKA FABRYKA OKIEN KNS Sp. z o.o.
                <br />
                ul. Młodzieżowa 67 B<br />
                44-300 Wodzisław Śląski
                <br />
                KRS: 0001019342
                <br />
                REGON: 240831844
                <br />
                NIP: 647 245 87 03
                <br />
                SĄD REJONOWY W GLIWICACH
                <br />
                X WYDZIAŁ GOSPODARCZY KRAJOWEGO REJESTRU SĄDOWEGO
                <br />
                KAPITAŁ ZAKŁADOWY: 2 000 000,00 ZŁ
                <br />
                NR BDO 000017036
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-4">
              <div>
                <h6 className="mb-6 text-sm font-semibold uppercase text-white">
                  {t('menu.offer')}
                </h6>
                <ul className="text-gray-400">
                  <li className="mb-4">
                    <Link href="/pvc" className="hover:underline">
                      {t('products.pvc')}
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href="/alu" className="hover:underline">
                      {t('products.alu')}
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href="/rollers" className="hover:underline">
                      {t('products.rollers')}
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href="/pergolas" className="hover:underline">
                      {t('products.pergolas')}
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href="/pvc/windows" className="hover:underline">
                      {t('pvc.windows')}
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h6 className="mb-6 text-sm font-semibold uppercase text-white">
                  {t('menu.about')}
                </h6>
                <ul className="text-gray-400">
                  <li className="mb-4">
                    <Link href="/about-us" className="hover:underline ">
                      {t('menu.meetKNS')}
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link
                      href="/distribution-network"
                      className="hover:underline"
                    >
                      {t('menu.distributionNetwork')}
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href="/partners" className="hover:underline">
                      {t('menu.partners')}
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href="/videos" className="hover:underline">
                      {t('menu.promotionVideos')}
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href="/certificates" className="hover:underline">
                      {t('downloads.certificates')}
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h6 className="mb-6 text-sm font-semibold uppercase text-white">
                  &nbsp;
                </h6>
                <ul className="text-gray-400">
                  <li className="mb-4">
                    <Link href="/news" className="hover:underline">
                      {t('menu.news')}
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href="/career" className="hover:underline">
                      {t('menu.career')}
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href="/installer-guide" className="hover:underline">
                      {t('menu.installerguide')}
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href="/subsidies" className="hover:underline">
                      {t('menu.subsidies')}
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href="/privacy-policy" className="hover:underline">
                      {t('menu.privacyPolicy')}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="my-6 sm:mx-auto border-gray-700 lg:my-8" />
          <div className="sm:flex sm:items-center sm:justify-between">
            <span className="text-sm sm:text-center text-gray-400">
              © {new Date().getFullYear()}{' '}
              {languages.flatMap(
                (item, index) =>
                  item.locale === lang && (
                    <Link
                      key={index}
                      href={`${item.href}`}
                      onClick={() => close()}
                      className="hover:underline"
                    >
                      KNS-OKNA
                    </Link>
                  )
              )}
              . All Rights Reserved.
            </span>
            <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0 pb-10">
              <a
                href="https://www.facebook.com/oknakns"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-white"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Facebook page</span>
              </a>
              <a
                href="https://www.instagram.com/knsokna/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-white"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Instagram page</span>
              </a>
              <a
                href="https://www.linkedin.com/company/kns-slaska-fabryka-okien"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10.4219V17H14.1412V10.8627C14.1412 9.3217 13.584 8.26933 12.1869 8.26933C11.1206 8.26933 10.4872 8.97783 10.2075 9.66386C10.1059 9.90903 10.0797 10.2495 10.0797 10.5934V16.9997H6.22063C6.22063 16.9997 6.27243 6.60517 6.22063 5.5292H10.08V7.15468C10.0723 7.16748 10.0613 7.17999 10.0544 7.19222H10.08V7.15468C10.5929 6.37479 11.5074 5.25985 13.5578 5.25985C16.0966 5.25985 18 6.89926 18 10.4219ZM2.18367 0C0.863624 0 0 0.8564 0 1.98158C0 3.08287 0.838588 3.96401 2.13302 3.96401H2.15805C3.504 3.96401 4.34086 3.08287 4.34086 1.98158C4.31525 0.8564 3.504 0 2.18367 0ZM0.22936 17H4.08704V5.5292H0.22936V17Z"
                    clipRule="evenodd"
                  />
                </svg>

                <span className="sr-only">LinkedIn page</span>
              </a>
              <a
                href="https://www.youtube.com/channel/UCNI2OpXl_JSqy2HkCYqgv8g"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 310 310"
                  fill="currentColor"
                >
                  <g>
                    <path
                      fillRule="evenodd"
                      d="M297.917,64.645c-11.19-13.302-31.85-18.728-71.306-18.728H83.386c-40.359,0-61.369,5.776-72.517,19.938
		C0,79.663,0,100.008,0,128.166v53.669c0,54.551,12.896,82.248,83.386,82.248h143.226c34.216,0,53.176-4.788,65.442-16.527
		C304.633,235.518,310,215.863,310,181.835v-53.669C310,98.471,309.159,78.006,297.917,64.645z M199.021,162.41l-65.038,33.991
		c-1.454,0.76-3.044,1.137-4.632,1.137c-1.798,0-3.592-0.484-5.181-1.446c-2.992-1.813-4.819-5.056-4.819-8.554v-67.764
		c0-3.492,1.822-6.732,4.808-8.546c2.987-1.814,6.702-1.938,9.801-0.328l65.038,33.772c3.309,1.718,5.387,5.134,5.392,8.861
		C204.394,157.263,202.325,160.684,199.021,162.41z"
                      clipRule="evenodd"
                    />
                  </g>
                </svg>

                <span className="sr-only">YouTube page</span>
              </a>
              <a
                href="https://twitter.com/KNSokna"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 512 512"
                  fill="currentColor"
                >
                  <g>
                    <path
                      fillRule="evenodd"
                      d="M500.398,94.784c-8.043,3.567-16.313,6.578-24.763,9.023c10.004-11.314,17.631-24.626,22.287-39.193
	c1.044-3.265-0.038-6.839-2.722-8.975c-2.681-2.137-6.405-2.393-9.356-0.644c-17.945,10.643-37.305,18.292-57.605,22.764
	c-20.449-19.981-48.222-31.353-76.934-31.353c-60.606,0-109.913,49.306-109.913,109.91c0,4.773,0.302,9.52,0.9,14.201
	c-75.206-6.603-145.124-43.568-193.136-102.463c-1.711-2.099-4.347-3.231-7.046-3.014c-2.7,0.211-5.127,1.734-6.491,4.075
	c-9.738,16.709-14.886,35.82-14.886,55.265c0,26.484,9.455,51.611,26.158,71.246c-5.079-1.759-10.007-3.957-14.711-6.568
	c-2.525-1.406-5.607-1.384-8.116,0.054c-2.51,1.439-4.084,4.084-4.151,6.976c-0.012,0.487-0.012,0.974-0.012,1.468
	c0,39.531,21.276,75.122,53.805,94.52c-2.795-0.279-5.587-0.684-8.362-1.214c-2.861-0.547-5.802,0.456-7.731,2.638
	c-1.932,2.18-2.572,5.219-1.681,7.994c12.04,37.591,43.039,65.24,80.514,73.67c-31.082,19.468-66.626,29.665-103.939,29.665
	c-7.786,0-15.616-0.457-23.279-1.364c-3.807-0.453-7.447,1.795-8.744,5.416c-1.297,3.622,0.078,7.66,3.316,9.736
	c47.935,30.735,103.361,46.98,160.284,46.98c111.903,0,181.907-52.769,220.926-97.037c48.657-55.199,76.562-128.261,76.562-200.451
	c0-3.016-0.046-6.061-0.139-9.097c19.197-14.463,35.724-31.967,49.173-52.085c2.043-3.055,1.822-7.094-0.545-9.906
	C507.7,94.204,503.76,93.294,500.398,94.784z"
                      clipRule="evenodd"
                    />
                  </g>
                </svg>

                <span className="sr-only">Twitter page</span>
              </a>
            </div>
          </div>
        </div>
      </footer>
      {cookieBar !== '1' ? (
        <motion.div
          className="text-xs bottom-0"
          animate={{
            position: 'absolute',
            y: [200, 0],
            transition: { easy: 'easyIn', delay: 1, duration: 0.1 },
          }}
        >
          <div className="w-screen mx-auto fixed bottom-0 bg-white p-1 flex gap-2 flex-wrap md:flex-nowrap text-center md:text-left items-center justify-center md:justify-between md:px-5">
            <div className="w-full">
              This website uses cookies to ensure you get the best experience on
              our website.{' '}
              <Link
                href="/privacy-policy"
                className="text-indigo-600 whitespace-nowrap  hover:underline"
              >
                Learn more
              </Link>
            </div>
            <div className="flex gap-4 items-center flex-shrink-0">
              <button
                onClick={() => hideConsent()}
                className="bg-green px-5 py-2 text-white rounded-md hover:bg-primaryHover focus:outline-none"
              >
                Got it!
              </button>
            </div>
          </div>
        </motion.div>
      ) : null}
    </>
  );
};
