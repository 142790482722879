import { Popover, Transition } from '@headlessui/react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { Fragment, useEffect, useState } from 'react';
import Image from 'next/image';
import { Bars3Icon, PhoneIcon, PlayIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

import ALU from '../svg/ALU.svg';
import Certificates from '../svg/Certificates.svg';
import Documents from '../svg/Documents.svg';
import KNSApp from '../svg/KNSApp.svg';
import Pergolas from '../svg/Pergolas.svg';
import PVC from '../svg/PVC.svg';
import Roller from '../svg/Roller.svg';
import { languages } from '@consts/consts';

function classNames(...classes: unknown[]) {
  return classes.filter(Boolean).join(' ');
}

export const DesktopMenu = () => {
  const { t, lang } = useTranslation('common');
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 150);
    });
  }, []);

  const solutions = [
    {
      name: t('products.pvc'),
      description:
        'Szerokie możliwości stosowania: okna, drzwi okienne, drzwi wejściowe, drzwi przesuwne',
      href: '/pvc',
      icon: PVC,
      products: [
        {
          name: t('pvc.windows'),
          href: '/pvc/windows',
        },
        {
          name: t('pvc.sliding-doors'),
          href: '/pvc/sliding-doors',
        },
        {
          name: t('pvc.doors'),
          href: '/pvc/doors',
        },
        {
          name: t('pvc.accessories'),
          href: '/pvc/accessories',
        },
        {
          name: 'V-Perfect',
          href: '/pvc/v-perfect',
        },
        {
          name: t('pvc.antiBurglary'),
          href: '/pvc/anti-burglary-windows',
        },
      ],
    },
    {
      name: t('products.alu'),
      description:
        'Różnorodność funkcji okien, dostosowana do potrzeb użytkowników',
      href: '/alu',
      icon: ALU,
      products: [
        {
          name: t('alu.windows'),
          href: '/alu/windows',
        },
        {
          name: t('alu.sliding-doors'),
          href: '/alu/sliding-doors',
        },
        {
          name: t('alu.facadeSystems'),
          href: '/alu/facade-systems',
        },
        {
          name: t('alu.doors'),
          href: '/alu/doors',
        },
        {
          name: t('alu.multilevel'),
          href: '/alu/multilevel',
        },
        // {
        //   name: t('alu.winter-gardens'),
        //   href: '/alu/winter-gardens',
        // },
        {
          name: t('alu.accessories'),
          href: '/alu/accessories',
        },
      ],
    },
    {
      name: t('products.rollers'),
      description:
        'Jest to produkt bardzo uniwersalny i wszechstronny, umożliwiający montaż bez zabudowy',
      href: '/rollers',
      icon: Roller,
      products: [
        {
          name: t('rollers.adaptive'),
          href: '/rollers/adaptive',
        },
        {
          name: t('rollers.concealed'),
          href: '/rollers/concealed',
        },
        {
          name: t('rollers.for-windows'),
          href: '/rollers/for-windows',
        },
        {
          name: t('rollers.screens'),
          href: '/rollers/screens',
        },
        {
          name: t('rollers.blinds'),
          href: '/rollers/blinds',
        },
        {
          name: t('rollers.lamella'),
          href: '/rollers/lamella',
        },
        {
          name: t('rollers.accessories'),
          href: '/rollers/accessories',
        },
      ],
    },
    {
      name: t('products.pergolas'),
      description:
        'Drzwi wejściowe to wizytówka Twojego domu! Wybierz solidne drzwi zewnętrzne aluminiowe',
      href: '/pergolas',
      icon: Pergolas,
      products: [
        {
          name: t('pergolas.MBOpenSky140'),
          href:
            lang === 'pl'
              ? '/pergolas/mb-opensky-140-3903'
              : '/pergolas/mb-opensky-140-3-3911',
        },
        {
          name: t('pergolas.MBOpenSky120'),
          href:
            lang === 'pl'
              ? '/pergolas/mb-opensky-120-nowosc-3905'
              : '/pergolas/mb-opensky-120-4151',
        },
      ],
    },
  ];

  const callsToAction = [
    { name: t('menu.promotionVideos'), href: '/videos', icon: PlayIcon },
    {
      name: t('menu.distributionNetwork'),
      href: '/distribution-network',
      icon: PhoneIcon,
    },
  ];

  const resources = [
    {
      name: t('downloads.certificates'),
      description: t('downloads.certificatesDesc'),
      href: '/certificates',
      icon: Certificates,
    },
    {
      name: t('downloads.program'),
      description: t('downloads.programDesc'),
      href: '/application',
      icon: KNSApp,
    },
    // {
    //   name: t('downloads.remote'),
    //   description: t('downloads.remoteDesc'),
    //   href: '/remote-connection',
    //   icon: RemoteConnection,
    // },
    {
      name: t('downloads.documents'),
      description: t('downloads.documentsDesc'),
      href: '/documents',
      icon: Documents,
    },
    {
      name: t('downloads.advertising'),
      description: t('downloads.advertisingDesc'),
      href: '/advertising-materials',
      icon: Documents,
    },
  ];

  return (
    <div
      className={`absolute w-screen bg-white px-4 sm:px-6 ${
        scroll ? 'bg-opacity-95' : 'bg-opacity-100'
      } `}
    >
      <div
        className={`flex max-w-7xl mx-auto justify-between items-center md:justify-start md:space-x-10 transition-all duration-500 ${
          scroll ? 'py-1' : 'py-3'
        }`}
      >
        <div className="flex justify-start lg:w-0 lg:flex-1">
          <Link href="/">
            <Image
              className={`w-auto cursor-pointer ${
                scroll ? 'h-12' : 'h-10 md:h-20'
              }`}
              width={102}
              height={80}
              src="/kns.webp"
              alt="KNS"
            />
          </Link>
        </div>

        <div className="-mr-2 -my-2 md:hidden">
          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span className="sr-only">Open menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
        </div>
        <Popover.Group as="nav" className="hidden md:flex space-x-10">
          <Popover className="relative">
            {({ open, close }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? 'text-gray-900' : 'text-gray-500',
                    'group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  )}
                >
                  <span>{t('menu.products')}</span>
                  <ChevronDownIcon
                    className={classNames(
                      open ? 'text-gray-600' : 'text-gray-400',
                      'ml-2 h-5 w-5 group-hover:text-gray-500'
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-30 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="relative grid gap-6 bg-white px-3 py-6 sm:gap-8 sm:p-5">
                        {solutions.map((item, index) => (
                          <div className="-m-3 p-3 flex w-full" key={index}>
                            <Image
                              src={item.icon.src}
                              alt={item.name}
                              className="h-6"
                              width={24}
                              height={24}
                            />
                            {/* <item.icon
                                  className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                  aria-hidden="true"
                                /> */}
                            <div className="ml-4 w-full">
                              <p className="text-base font-medium text-gray-900">
                                <Link
                                  href={item.href}
                                  onClick={() => close()}
                                  className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                >
                                  {item.name}
                                </Link>
                              </p>
                              <div className="mt-1 text-sm text-gray-500">
                                {/* {item.description} */}
                                <ul>
                                  {item.products?.map((product, index) => (
                                    <li key={index}>
                                      <Link
                                        href={product.href}
                                        onClick={() => close()}
                                      >
                                        {product.name}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                        {callsToAction.map((item, index) => (
                          <div key={index} className="flow-root">
                            <Link
                              href={item.href}
                              onClick={() => close()}
                              className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                            >
                              <div className="flex">
                                {/* <item.icon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    /> */}
                                <span className="ml-3">{item.name}</span>
                              </div>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          <Link
            href="/about-us"
            className="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            {t('menu.about')}
          </Link>
          <Link
            href="/gallery"
            className="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            {t('menu.gallery')}
          </Link>

          <Popover className="relative">
            {({ open, close }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? 'text-gray-900' : 'text-gray-500',
                    'group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  )}
                >
                  <span>{t('menu.downloads')}</span>
                  <ChevronDownIcon
                    className={classNames(
                      open ? 'text-gray-600' : 'text-gray-400',
                      'ml-2 h-5 w-5 group-hover:text-gray-500'
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-30 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                        {resources.map((item, index) => (
                          <Link
                            key={index}
                            href={item.href}
                            onClick={() => close()}
                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                          >
                            <div className="flex">
                              <Image
                                src={item.icon.src}
                                alt={item.name}
                                width={24}
                                height={24}
                              />
                              {/* <item.icon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  /> */}
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">
                                  {item.name}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                  {item.description}
                                </p>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
          <Link
            href={`/news`}
            className="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            {t('menu.news')}
          </Link>
          <Link
            href={`/contact`}
            className="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            {t('menu.contact')}
          </Link>
        </Popover.Group>
        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <Popover className="relative">
              {({ open, close }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    )}
                  >
                    <div className="pr-3">
                      <Image
                        src={`/img/locales/${lang}.svg`}
                        alt={lang}
                        width="24"
                        height="24"
                        className="rounded-full drop-shadow-md border-red"
                      />
                    </div>
                    <span>{lang.toUpperCase()}</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-30 -ml-4 mt-3 transform px-2  sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-4 bg-white px-3 py-3 sm:gap-6 sm:p-4">
                          {languages.flatMap(
                            (item, index) =>
                              item.locale !== lang && (
                                <a
                                  key={index}
                                  href={`${item.href}`}
                                  onClick={() => close()}
                                  className="-m-3 p-3 pr-8 flex items-start rounded-lg hover:bg-gray-90"
                                >
                                  <Image
                                    src={`/img/locales/${item.locale}.svg`}
                                    width="24"
                                    height="24"
                                    alt={item.locale}
                                    className="rounded-full drop-shadow-md"
                                  />
                                  <div className="ml-3">
                                    <p className="text-base font-medium text-gray-900">
                                      {item.name}
                                    </p>
                                  </div>
                                </a>
                              )
                          )}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>
        </div>
      </div>
    </div>
  );
};
