import { Footer } from '@components/footer';
import { Header } from '@components/header/header';

type Props = {
  children: JSX.Element;
};

export default function Layout({ children }: Props) {
  return (
    <>
      <Header />
      <div className="pt-12 sm:pt-24">{children}</div>
      <Footer />
    </>
  );
}
