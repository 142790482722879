module.exports = {
  locales: ['pl', 'en', 'de', 'fr', 'hu', 'it', 'cs'],
  defaultLocale: 'pl',
  localeDetection: false,
  // logger: () => null,
  pages: {
    '*': ['common'],
    'rgx:^/pvc/': ['pvc'],
    '/': ['main'],
    // '/blog': ['blog'],
    // '/blog/[slug]': ['blog'],
    // '/blog/category': ['blog'],
    // '/blog/category/[slug]': ['blog'],
  },
};
